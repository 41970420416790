<template>
  <div>
    <c-search-box @enter="getBatchList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-table
          ref="table"
          title="배치 목록"
          tableId="table"
          :columnSetting="false"
          :isFullScreen="false"
          :columns="grid.columns"
          :data="grid.data"
          :isExcelDown="false"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getBatchList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <q-form ref="editForm">
          <c-card title="상세" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="신규" icon="add" @btnClicked="addBatch" />
                <c-btn
                  v-if="editable && deleteable"
                  :url="deleteUrl"
                  :isSubmit="true"
                  :param="data"
                  mappingType="DELETE"
                  label="삭제"
                  icon="remove"
                  @beforeAction="deleteBatch"
                  @btnCallback="deleteCallback" />
                <c-btn
                  v-if="editable && saveable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveBatch"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="배치 클래스명"
                  name="batchClassName"
                  v-model="data.batchClassName">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="배치명"
                  name="batchName"
                  v-model="data.batchName">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :editable="false"
                  label="크론 표현식"
                  name="cronOption"
                  v-model="data.cronOption">
                </c-text>
              </div>
              <div class="col-6">
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="사용여부"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
              <div class="col-12">
                <c-textarea
                  :editable="editable && dataeditable"
                  :rows="2"
                  label="배치 설명"
                  name="remark"
                  v-model="data.remark">
                </c-textarea>
              </div>
              <div class="col-12">
                <c-card title="반복주기" class="cardClassDetailForm">
                  <template slot="card-detail">
                    <div class="col-3">
                      <c-radio
                        :editable="editable && dataeditable"
                        :comboItems="rptItems"
                        label="반복주기 형태"
                        name="batchCycleRpt"
                        v-model="data.batchCycleRpt">
                      </c-radio>
                    </div>
                    <div class="col-3" v-show="data.batchCycleRpt == '01'">
                      <c-radio
                        :editable="editable && dataeditable"
                        :comboItems="rpt1Items"
                        label="매월/매주 구분"
                        name="batchCycleRptType1"
                        v-model="data.batchCycleRptType1">
                      </c-radio>
                    </div>
                    <div class="col-3" v-show="data.batchCycleRpt == '01' && data.batchCycleRptType1 == 'm'">
                      <c-select
                        type="edit"
                        :editable="editable && dataeditable"
                        :comboItems="rpt2Items"
                        itemText="codeName"
                        itemValue="code"
                        label="매월"
                        name="batchCycleRptType2"
                        v-model="data.batchCycleRptType2">
                      </c-select>
                    </div>
                    <div class="col-3" v-show="data.batchCycleRpt == '01' && data.batchCycleRptType1 == 'w'">
                      <c-select
                        type="edit"
                        :editable="editable && dataeditable"
                        :comboItems="rpt3Items"
                        itemText="codeName"
                        itemValue="code"
                        label="매주"
                        name="batchCycleRptType3"
                        v-model="data.batchCycleRptType3">
                      </c-select>
                    </div>
                    <div class="col-3" v-show="data.batchCycleRpt == '02'">
                      <c-select
                        :editable="editable && dataeditable"
                        :comboItems="rpt4Items"
                        itemText="codeName"
                        itemValue="code"
                        label="매일반복"
                        name="batchCycleRptType4"
                        v-model="data.batchCycleRptType4">
                      </c-select>
                    </div>
                  </template>
                </c-card>
              </div>
              <div class="col-12">
                <c-card title="실행주기" class="cardClassDetailForm">
                  <template slot="card-detail">
                    <div class="col-3">
                      <c-radio
                        :editable="editable && dataeditable"
                        :comboItems="exeItems"
                        label="실행주기 구분"
                        name="batchCycleExe"
                        v-model="data.batchCycleExe">
                      </c-radio>
                    </div>
                    <div class="col-3" v-show="data.batchCycleExe == '02'">
                      <c-radio
                        :editable="editable && dataeditable"
                        :comboItems="rpt5Items"
                        label="시간/분 구분"
                        name="batchCycleRptType5"
                        v-model="data.batchCycleRptType5">
                      </c-radio>
                    </div>
                    <div class="col-3" v-show="data.batchCycleExe == '01' || data.batchCycleRptType5 == 'h'">
                      <c-select
                        type="edit"
                        :editable="editable && dataeditable"
                        :comboItems="hourItems"
                        itemText="codeName"
                        itemValue="code"
                        label="시간"
                        name="batchCycleHour"
                        v-model="data.batchCycleHour">
                      </c-select>
                    </div>
                    <div class="col-3" v-show="data.batchCycleExe == '01' || data.batchCycleRptType5 == 'm'">
                      <c-select
                        type="edit"
                        :editable="editable && dataeditable"
                        :comboItems="minItems"
                        itemText="codeName"
                        itemValue="code"
                        label="분"
                        name="batchCycleMin"
                        v-model="data.batchCycleMin">
                      </c-select>
                    </div>
                  </template>
                </c-card>
                <br>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'batch-manage',
  data() {
    return {
      searchParam: {
        useFlag: 'Y',
      },
      grid: {
        columns: [
          {
            name: 'batchClassName',
            field: 'batchClassName',
            label: '배치 클래스명',
            align: 'center',
            sortable: true,
          },
          {
            name: 'batchName',
            field: 'batchName',
            label: '배치명',
            align: 'center',
            sortable: true,
          },
          {
            name: 'cronOption',
            field: 'cronOption',
            label: '크론 표현식',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
        ],
        data: [],
      },
      data: {
        batchId: '',  // 배치아이디
        batchClassName: '',  // 배치 클래스명
        batchName: '',  // 배치명
        cronOption: '',  // 크론 표현식
        remark: '',  // 설명
        batchCycleRpt: '01',  // 반복주기 형태
        batchCycleRptType1: 'm',  // 매월매주구분
        batchCycleRptType2: '1',  // 고정일자
        batchCycleRptType3: '1',  // 고정요일
        batchCycleRptType4: '매일',  // 반복text
        batchCycleRptType5: 'h',  // 실행주기 시간/분 구분
        batchCycleExe: '01',  // 실행주기 구분
        batchCycleHour: '1',  // 실행주기 시간
        batchCycleMin: '1',  // 실행주기 분
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자
        regDt: '',  // 등록일시
        chgUserId: '',  // 수정자
        chgDt: '',  // 수정일시
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      rptItems: [
        { code: '01', codeName: '고정' },
        { code: '02', codeName: '반복' },
      ],
      rpt1Items: [
        { code: 'm', codeName: '매월' },
        { code: 'w', codeName: '매주' },
      ],
      rpt2Items: [
        { code: '1', codeName: '매월 1일' },
        { code: '2', codeName: '매월 2일' },
        { code: '3', codeName: '매월 3일' },
        { code: '4', codeName: '매월 4일' },
        { code: '5', codeName: '매월 5일' },
        { code: '6', codeName: '매월 6일' },
        { code: '7', codeName: '매월 7일' },
        { code: '8', codeName: '매월 8일' },
        { code: '9', codeName: '매월 9일' },
        { code: '10', codeName: '매월 10일' },
        { code: '11', codeName: '매월 11일' },
        { code: '12', codeName: '매월 12일' },
        { code: '13', codeName: '매월 13일' },
        { code: '14', codeName: '매월 14일' },
        { code: '15', codeName: '매월 15일' },
        { code: '16', codeName: '매월 16일' },
        { code: '17', codeName: '매월 17일' },
        { code: '18', codeName: '매월 18일' },
        { code: '19', codeName: '매월 19일' },
        { code: '20', codeName: '매월 20일' },
        { code: '21', codeName: '매월 21일' },
        { code: '22', codeName: '매월 22일' },
        { code: '23', codeName: '매월 23일' },
        { code: '24', codeName: '매월 24일' },
        { code: '25', codeName: '매월 25일' },
        { code: '26', codeName: '매월 26일' },
        { code: '27', codeName: '매월 27일' },
        { code: '28', codeName: '매월 28일' },
        { code: '29', codeName: '매월 29일' },
        { code: '30', codeName: '매월 30일' },
      ],
      rpt3Items: [
        { code: '1', codeName: '일요일' },
        { code: '2', codeName: '월요일' },
        { code: '3', codeName: '화요일' },
        { code: '4', codeName: '수요일' },
        { code: '5', codeName: '목요일' },
        { code: '6', codeName: '금요일' },
        { code: '7', codeName: '토요일' },
      ],
      rpt4Items: [
        { code: '매일', codeName: '매일반복' },
      ],
      exeItems: [
        { code: '01', codeName: '고정' },
        { code: '02', codeName: '반복' },
      ],
      rpt5Items: [
        { code: 'h', codeName: '시간' },
        { code: 'm', codeName: '분' },
      ],
      hourItems: [
        { code: '0', codeName: '00' },
        { code: '1', codeName: '01' },
        { code: '2', codeName: '02' },
        { code: '3', codeName: '03' },
        { code: '4', codeName: '04' },
        { code: '5', codeName: '05' },
        { code: '6', codeName: '06' },
        { code: '7', codeName: '07' },
        { code: '8', codeName: '08' },
        { code: '9', codeName: '09' },
        { code: '10', codeName: '10' },
        { code: '11', codeName: '11' },
        { code: '12', codeName: '12' },
        { code: '13', codeName: '13' },
        { code: '14', codeName: '14' },
        { code: '15', codeName: '15' },
        { code: '16', codeName: '16' },
        { code: '17', codeName: '17' },
        { code: '18', codeName: '18' },
        { code: '19', codeName: '19' },
        { code: '20', codeName: '20' },
        { code: '21', codeName: '21' },
        { code: '22', codeName: '22' },
        { code: '23', codeName: '23' },
      ],
      minItems: [
        { code: '1', codeName: '01' },
        { code: '5', codeName: '05' },
        { code: '10', codeName: '10' },
        { code: '15', codeName: '15' },
        { code: '20', codeName: '20' },
        { code: '25', codeName: '25' },
        { code: '30', codeName: '30' },
        { code: '35', codeName: '35' },
        { code: '40', codeName: '40' },
        { code: '45', codeName: '45' },
        { code: '50', codeName: '50' },
        { code: '55', codeName: '55' },
      ],
      listUrl: '',
      detailUrl: '',
      checkUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      deleteable: false,
      editable: true,
      dataeditable: false,
      updateMode: false,
      isSave: false,
      saveUrl: transactionConfig.sys.batch.insert.url,
      saveType: 'POST',
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = (this.$store.getters.user.userId === 'U000000001' ? this.$route.meta.editable : false);
      this.dataeditable = false;
      // api scope
      this.listUrl = selectConfig.sys.batch.list.url;
      this.detailUrl = selectConfig.sys.batch.get.url;
      this.checkUrl = selectConfig.sys.batch.check.url;
      this.insertUrl = transactionConfig.sys.batch.insert.url;
      this.updateUrl = transactionConfig.sys.batch.update.url;
      this.deleteUrl = transactionConfig.sys.batch.delete.url;
      // list scope
      this.getList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getBatchList() {
      this.getList();
      this.rowRemoveSelect();
      this.reset();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
      this.saveable = false;
      this.deleteable = false;
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.updateMode = true;
      this.$http.url = this.$format(this.detailUrl, row.batchId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.dataeditable = true;
      },
      () => {
      });
    },
    addBatch() {
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.data = {
        batchId: '',  // 배치아이디
        batchClassName: '',  // 배치 클래스명
        batchName: '',  // 배치명
        cronOption: '',  // 크론 표현식
        remark: '',  // 설명
        batchCycleRpt: '01',  // 반복주기 형태
        batchCycleRptType1: 'm',  // 매월매주구분
        batchCycleRptType2: '1',  // 고정일자
        batchCycleRptType3: '1',  // 고정요일
        batchCycleRptType4: '매일',  // 반복text
        batchCycleRptType5: 'h',  // 실행주기 시간/분 구분
        batchCycleExe: '01',  // 실행주기 구분
        batchCycleHour: '1',  // 실행주기 시간
        batchCycleMin: '1',  // 실행주기 분
        useFlag: 'Y',  // 사용여부
      };
      this.rowRemoveSelect();
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.data = {
        batchId: '',  // 배치아이디
        batchClassName: '',  // 배치 클래스명
        batchName: '',  // 배치명
        cronOption: '',  // 크론 표현식
        remark: '',  // 설명
        batchCycleRpt: '01',  // 반복주기 형태
        batchCycleRptType1: 'm',  // 매월매주구분
        batchCycleRptType2: '1',  // 고정일자
        batchCycleRptType3: '1',  // 고정요일
        batchCycleRptType4: '매일',  // 반복text
        batchCycleRptType5: 'h',  // 실행주기 시간/분 구분
        batchCycleExe: '01',  // 실행주기 구분
        batchCycleHour: '1',  // 실행주기 시간
        batchCycleMin: '1',  // 실행주기 분
        useFlag: 'Y',  // 사용여부
      };
    },
    setCrontabOption() {
      let cronexpression = '';
      if (this.data.batchCycleRpt == '02') {// 반복주기 반복
        if (this.data.batchCycleExe == '02') {// 실행주기 반복
          if (this.data.batchCycleRptType5 == 'h') {// 실행주기 시간/분 구분(시간)
            cronexpression = '0 0 0/'+this.data.batchCycleHour+' * * ?';
          } else if (this.data.batchCycleRptType5 == 'm') { // 실행주기 시간/분 구분(분)
            cronexpression = '0 0/'+this.data.batchCycleMin+' * * * ?';
          }
        } else if (this.data.batchCycleExe == '01') {// 실행주기 고정
          cronexpression = '0 '+ this.data.batchCycleMin + ' ' + this.data.batchCycleHour + ' * * ?';
        }
      } else if (this.data.batchCycleRpt == '01') {// 반복주기 고정
        var fixexp = '';
        if (this.data.batchCycleRptType1 == 'm') {// 반복주기 월/주 구분(월)
          fixexp = ' ' + this.data.batchCycleRptType2 + ' * ?';
        } else if (this.data.batchCycleRptType1 == 'w') { // 반복주기 월/주 구분(주)
          fixexp = ' ? * ' + this.data.batchCycleRptType3;
        }
        if (this.data.batchCycleExe == '01') {// 실행주기 고정
          cronexpression = '0 '+ this.data.batchCycleMin + ' ' + this.data.batchCycleHour + fixexp;
        } else if (this.data.batchCycleExe == '02') {// 실행주기 반복
          if (this.data.batchCycleRptType5 == 'h') {// 실행주기 시간/분 구분(시간)
            cronexpression = '0 0 0/'+this.data.batchCycleHour + fixexp;
          } else if (this.data.batchCycleRptType5 == 'm') { // 실행주기 시간/분 구분(분)
            cronexpression = '0 0/'+this.data.batchCycleMin+' *' + fixexp;
          }
        }
      }
      this.data.cronOption = cronexpression;
    },
    saveBatch() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          this.$http.url = this.$format(this.checkUrl, this.data.batchClassName);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            if (this.updateMode || _result.data === 0) {
              this.setCrontabOption();
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.data.regUserId = this.$store.getters.user.userId;
                  this.data.chgUserId = this.$store.getters.user.userId;
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내', // 안내
                message:
                '동일한 클래스명이 존재합니다.',
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteBatch() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.batchId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isSave = !this.isSave;
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.saveType == 'PUT') {
        this.getList();
        this.rowClick({ batchId: result.data.batchId })
      } else {
        this.getBatchList();
      }
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getBatchList();
    },
  }
};
</script>
